.navbar-fixed-top {
  background:transparent;
  border:0;
  margin:0;
  .navbar-header {
    .navbar-brand {
      padding:15px 15px 15px 30px;
      width:75px;
      height:75px;
      img {
        width:60px;
        height:60px;
        transition:ease all 0.5s;
        &.logo {
          opacity:1;
          width:120px;
          height:120px;
        }
        &.logo-small {
          width:0;
          height:0;
        }
      }
    }
  }
  #navbar {
    .navbar-right {
      padding-top:15px;
      padding-right:15px;
      transition:ease all 0.5s;
      li {
        a {
          color:white;
          padding:12px 0;
          margin:0 15px;
          font-size:1.25em;
          text-transform:lowercase;
          letter-spacing:1px;
          font-weight:300;
          transition:all ease 0.2s;
          &:hover,
          &:focus {
            border-bottom:2px solid white;
          }
        }
        &.active {
          a {
            background: transparent;
            border-bottom:2px solid white;
          }
        }
      }
    }
  }
  &.fixed {
    position:fixed;
    top:0;
    background:white;
    z-index:1000;
    border-bottom:1px solid #eee;
    .navbar-header {
      .navbar-brand {
        width:50px;
        height:50px;
        padding:0;
        margin:5px;
        img {
          width:50px;
          height:50px;
          &.logo {
            opacity:0;
            width:0;
            height:0;
          }
          &.logo-small {
            width:150px;
          }
        }
      }
    }
    #navbar {
      .navbar-right {
        padding-top:0;
        li {
          margin:0;
          padding:0;
          a {
            margin:0;
            padding:18px 15px;
            border-left:1px solid #eee;
            color:black;
            height:60px;
            overflow:hidden;
            text-align:center;
            &:hover,
            &:focus {
              color:$red;
              border-bottom:0;
            }
          }
          &.active {
            a {
              color:$red;
              border-bottom:0;
              background:#fafafa;
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 768px) {
  .navbar-fixed-top {
    button {
      display:none;
    }
  }
}