html, body {
  padding: 0;
  margin: 0;
}

html {
  height: 100%;
}

body {
  font-family: 'Source Sans Pro', sans-serif;
  min-height: 100%;
  height: 100%;
  overflow-x: hidden;
}

p {
  font-family: $font-source;
  font-size: 1.25em;
  font-weight: 300;
  color: black;
  line-height: 2.1em;
}

p, ul, ol {
  margin: 0 0 40px 0;
}

.main ul {
  margin: -40px 0 40px -20px;
}

a {
  color: $red;
  outline: 0;
  &:hover,
  &:focus {
    color: $red;
    outline: none;
  }
}

h2, h3, h4 {
  position: relative;
  &.gradient {
    color: $red;
    background: -webkit-linear-gradient(left, #bd1717, #e71866);
    background: -o-linear-gradient(right, #bd1717, #e71866);
    background: -moz-linear-gradient(right, #bd1717, #e71866);
    background: linear-gradient(to right, #bd1717, #e71866);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

h2 {
  font-size: 3.8em;
  font-weight: 900;
  font-family: $font-source;
  line-height: 62px;
  margin: 0 0;
  padding: 0 0 30px 0;
  &.large {
    padding: 30px 0;
    margin: 0;
    position: relative;
    display: block;
    text-align: center;
    color: white;
    background: -webkit-linear-gradient(left, #bd1717, #e71866);
    background: -o-linear-gradient(right, #bd1717, #e71866);
    background: -moz-linear-gradient(right, #bd1717, #e71866);
    background: linear-gradient(to right, #bd1717, #e71866);
  }
}

.v-container {
  display: table;
  height: 100%;
  .v-inner {
    display: table-cell;
    vertical-align: middle;
  }
}

.pp {
  transition-property: opacity, transform;
  transition-duration: 0.8s;
  opacity: 0;
  transform: translateY(25px);
}

.pp.show {
  transform: translateY(0px);
  opacity: 1;
}

section {
  &.white,
  &.grey {
    width: 100%;
    position: relative;
  }
  &.red,
  &.white,
  &.grey {
    background: white;
    /*
    max-width:1400px;
    margin:0 auto;
    display:flex;
    */
    padding: 90px 0 90px;
    .main {
      max-width: 800px;
      margin: 0 auto;
      h2 {
        font-size: 2.8em;
        line-height: 40px;
        color: $red;
        background: -webkit-linear-gradient(left, #bd1717, #e71866);
        background: -o-linear-gradient(right, #bd1717, #e71866);
        background: -moz-linear-gradient(right, #bd1717, #e71866);
        background: linear-gradient(to right, #bd1717, #e71866);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      h3 {
        margin-bottom:0;
      }
      li {
        font-family: $font-source;
        font-size: 1.25em;
        font-weight: 300;
        color: black;
        line-height: 2.1em;
      }
    }
  }
  &.red {
    background: $red;
    background: -webkit-linear-gradient(left, #bd1717, #e71866);
    background: -o-linear-gradient(right, #bd1717, #e71866);
    background: -moz-linear-gradient(right, #bd1717, #e71866);
    background: linear-gradient(to right, #bd1717, #e71866);
    color: white;
    max-width: 100%;
    position: relative;
  }
  &.grey {
    background: #fafafa;
    float: left;
  }
}

.block-icon {
  max-width: 800px;
  margin: 0 auto;
}

.block-half {
  width: 50%;
  border: 1px solid #eee;
  padding: 90px;
  float: left;
  min-height: 650px;
  display: block;
}

.block-half:first-child,
.block-half:last-child {
  background: white;
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    position: relative;
    margin: 0 0 60px 0;
    border-top: 1px solid #eee;
    padding: 60px 0 0 0;
    float: left;
    width: 100%;
    h3 {
      margin: 0;
      padding: 0;
      color: $red;
      background: -webkit-linear-gradient(left, #bd1717, #e71866);
      background: -o-linear-gradient(right, #bd1717, #e71866);
      background: -moz-linear-gradient(right, #bd1717, #e71866);
      background: linear-gradient(to right, #bd1717, #e71866);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    p {
      margin: 0;
    }
    &:last-child {
      padding-bottom: 30px;
    }
  }
  li:nth-child(odd) {
    padding-left: 200px;
    img {
      margin-top: 30px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(20%, -50%);
    }
  }
  li:nth-child(even) {
    padding-right: 200px;
    img {
      margin-top: 30px;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(20%, -50%);
    }
  }
}

#about-intro {
  padding-bottom: 143px;
}

.intro {
  position: relative;
  z-index: 1;
  &:after {
    content: "";
    width: 100%;
    height: 250px;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=0); /* IE6-9 */
  }
  #more-info {
    position: relative;
    z-index: 9;
  }
}

.advantage {
  max-width: 800px;
  margin: 0 auto;
}

.list-advantage {
  list-style: none;
  display: inline-block;
  margin: 50px 0 0 0;
  padding: 0;
  li {
    display: inline-block;
    float: left;
    margin: 0 90px 0 0;
    .advantage-icon {
      display: block;
      width: 100px;
      height: 100px;
      text-align: center;
      margin: 0 auto 10px auto;
    }
    .advantage-uitbreidbaar {
      padding-top: 10px;
    }
    img {
      display: block;
      margin: 0 auto;
    }
    span {
      display: block;
      text-align: center;
      font-size: 24px;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

.col-half {
  width: 50%;
  padding: 90px;
}

.table-striped {
  thead {
    tr {
      th {
        font-size:18px;
        font-weight:400;
        background:#222;
        color:white;
        border-bottom:0;
      }
    }
  }
  tbody {
    tr {
      td {
        border:0;
        font-size:1.25em;
        font-weight:300;
      }
    }
  }
}