.btn {
  padding:12px 20px;
}
.btn-primary {
  position:relative;
  background: rgb(38,172,193); /* Old browsers */
  background: -moz-linear-gradient(45deg, rgba(38,172,193,1) 0%, rgba(55,194,145,1) 100%);
  background: -webkit-linear-gradient(45deg, rgba(38,172,193,1) 0%,rgba(55,194,145,1) 100%);
  background: linear-gradient(45deg, rgba(38,172,193,1) 0%,rgba(55,194,145,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#26acc1', endColorstr='#37c291',GradientType=1 );
  color:white;
  border:0;
  border-bottom:3px solid rgb(38,172,193);
  font-size:1.25em;
  text-transform:uppercase;
  -webkit-transition: background-color 2s ease-out;
  -moz-transition: background-color 2s ease-out;
  -o-transition: background-color 2s ease-out;
  transition: background-color 2s ease-out;
  z-index:100;
  &:before {
    border-radius: inherit;
    background: rgb(38,172,193); /* Old browsers */
    background: -moz-linear-gradient(45deg, rgba(38,172,193,1) 0%, rgba(55,194,145,1) 100%);
    background: -webkit-linear-gradient(45deg, rgba(38,172,193,1) 0%,rgba(55,194,145,1) 100%);
    background: linear-gradient(45deg, rgba(38,172,193,1) 0%,rgba(55,194,145,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#26acc1', endColorstr='#37c291',GradientType=1 );
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    top: 0; left: 0;
    opacity: 0;
    width: 100%;
    z-index: -100;
    transition: opacity 0.45s;
  }
  &:hover,
  &:focus {
    border-bottom:3px solid rgb(55,194,145);
    &:before {
      opacity:1;
    }
  }
}
.btn-more-info {
  margin:0 auto;
  font-size:16px;
  text-transform:uppercase;
  display:inline-block;
  max-width:200px;
  position:absolute;
  left:0;
  right:0;
  padding:14px 25px;
  border-radius:6px;
  text-align:center;
  color:white;
  &:hover,
  &:focus {
    text-decoration:none;
  }
}

.btn-back {
  padding:15px 30px;
  border:5px solid $red;
  text-align:center;
  border-radius:30px;
  display:inline-block;
  color:$red;
  font-size:1.25em;
}

.btn-vacancy {
  position:absolute;
  right:67px;
  bottom:25px;
  color:white;
  z-index:100;
  width:238px;
  height:55px;
  display:block;
  span {
    display:block;
    z-index:200;
    font-weight:900;
    position:relative;
    font-size:1.75em;
  }
  span + span {
    z-index:200;
    font-weight:400;
    font-size:1.25em;
  }
  &:before {
    content: "";
    background-image: url(/images/flashlight.png);
    background-repeat: no-repeat;
    background-size: 100%;
    position: absolute;
    right: -90px;
    bottom: 165px;
    width: 75px;
    height: 234px;
    transform: rotate(-150deg);
    z-index: 99;
  }
  &:after {
    content: "";
    position: absolute;
    right: -163px;
    bottom: -265px;
    border-bottom: 480px solid #ffe96f;
    border-left: 239px solid transparent;
    border-right: 250px solid transparent;
    height: 0;
    width: 555px;
    z-index: 20;
    transform: rotate(29deg);
    opacity: .25;
    transition:ease all 0.25s;
  }
  &:hover,
  &:focus {
    color:black;
    text-decoration: none;
  }
  &.pressed {
    span {
      opacity:0.25;
    }
    &:after {
      opacity:0;
    }
  }
}
.btn-off {
  width: 25px;
  height: 25px;
  position: absolute;
  background: $red;
  z-index: 250;
  display: block;
  right: 0px;
  bottom: 301px;
  border-radius: 50%;
  cursor:pointer;
}

