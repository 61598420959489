@media only screen and (max-width : 991px) {
  header {
    &.large {
      max-height:500px;
      h1 {
        font-size:2.5em;
      }
    }
    &.heading {
      .header-text {
        width:90%;
      }
    }
  }
  .projects {
    padding:75px 75px;
  }

  footer {
    .container-fluid {
      .col-md-4 {
        &:last-child {
          .column {
            padding-left:30px !important;

            .certificate-logo {
              img {
                float:none;
                margin:0 auto;
                display:block;
              }
              img + a {
                margin-left:25px;
                margin-top:25px;
                float:none;
                display:block;
              }
            }
          }
        }
      }
    }
  }
}