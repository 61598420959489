@media only screen and (max-width: 767px) {
  .navbar-fixed-top {
    top:0;
    background:white;
    z-index:1000;
    border-bottom:1px solid #eee;
    margin: 0;
    button {
      background: transparent;
      border: 0;
      float: right;
      width: 40px;
      height: 35px;
      position: relative;
      margin: 18px 18px 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .5s ease-in-out;
      -moz-transition: .5s ease-in-out;
      -o-transition: .5s ease-in-out;
      transition: .5s ease-in-out;
      cursor: pointer;
      span {
        display: block;
        position: absolute;
        height: 3px;
        border-radius:3px;
        width: 100%;
        background: $red;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;
      }
      span:nth-child(1) {
        top: 0;
      }

      span:nth-child(2) {
        top: 10px;
      }

      span:nth-child(3) {
        top: 20px;
      }
    }
    .menu-toggle.open span:nth-child(1) {
      top: 12px;
      -webkit-transform: rotate(135deg);
      -moz-transform: rotate(135deg);
      -o-transform: rotate(135deg);
      transform: rotate(135deg);
    }

    .menu-toggle.open span:nth-child(2) {
      opacity: 0;
      left: -60px;
    }

    .menu-toggle.open span:nth-child(3) {
      top: 12px;
      -webkit-transform: rotate(-135deg);
      -moz-transform: rotate(-135deg);
      -o-transform: rotate(-135deg);
      transform: rotate(-135deg);
    }
    .navbar-header {
      .navbar-brand {
        width:auto;
        height:50px;
        padding:10px 0;
        margin:5px 15px;
        img {
          width:auto;
          height:50px;
          &.logo {
            opacity:0;
            width:0;
            height:0;
          }
          &.logo-small {
            width:125px;
            height:auto;
            opacity:1;
          }
        }
      }
    }
    #navbar {
      .navbar-right {
        padding-right:0;
        padding-top:0;
        margin:0 -15px;
        background:black;
        li {
          margin:0;
          padding:0;
          a {
            margin:0;
            background:black;
            padding:18px 15px;
            background:#f5f5f5;
            border-bottom:1px solid #eee;
            border-left:1px solid #eee;
            color:black;
            height:56px;
            font-size:1.5em;
            overflow:hidden;
            text-align:center;
            &:hover,
            &:focus {
              color:$red;
              border-bottom:0;
            }
          }
          &.active {
            a {
              color:$red;
              border-bottom:0;
              background:#fafafa;
            }
          }
        }
      }
    }
    .navbar-toggle {
      border:0;
      margin-top:12px;
      .icon-bar {
        background:$red;
        width:28px;
        height:3px;
        border-radius:2px;
      }
      &:hover,
      &:focus {
        background:transparent;
      }
    }

    &.fixed {
      .navbar-header {
        .navbar-brand {
          width: auto;
          padding:7px 0;
          margin: 5px 15px;
        }
      }
      #navbar {
        .navbar-right {
          li {
            a {
              height:56px;
              padding:16px 15px;
            }
          }
        }
      }
    }
  }

  header {
    margin-top:60px;
    &.heading {
      min-height:380px;
      max-height:380px;
      background-attachment: scroll !important;
      .header-text {
        width: 100%;
        h1 {
          font-size:2.5em;
          padding:0 15px;
        }
        p {
          padding: 0 15px;
          font-size:1.25em;
          line-height:1.5em;
        }
      }
    }
    &.large {
      max-height:400px;
      h1 {
        width:100%;
        padding:0 25px;
        margin:0;
        font-size:2.1em;
      }
    }
  }

  section {
    &.red,
    &.white,
    &.grey {
      padding:45px 0 90px 0;
      float:left;
      width:100%;
      h2 {
        margin:0 0 30px 0;
        padding:0;
        font-size:2.25em;
        line-height:36px;
      }
      .main {
        max-width:100%;
        padding:0 15px;
      }
    }
  }

  #research_messages {
    padding:45px 0;
  }

  #research_credits {
    .logo {
      width:100%;
    }
    .logo + .logo {
      margin-top:40px;
    }
  }

  .col-half {
    width: 100%;
    padding: 20px 15px;
  }
  #home-support {
    .center {
      padding:0 25px;
    }
    .buttons {
      .icon-item {
        width:100%;
        margin:0 auto 30px auto;
      }
    }
  }
  #home-projects {
    display:block;
    max-width:100%;
    margin-bottom:0;
    .item {
      height:350px !important;
      .title {
        .short {
          display:none;
        }
      }
    }
    .w-50 {
      width:100%;
    }
  }
  .intro {
    p {
      text-align:center;
    }
  }
  #methods {
    .row-method {
      float: left;
      width: 100%;
      position: relative;
      .col-half {
        min-height: 1px;
        padding-bottom: 0;
        .text {
          position: relative;
          top: 0;
          transform: translate(0, 0);
          width: 100%;
          padding: 0 0px;
          text-align:center;
          p {
            font-size:1.25em;
          }
        }
        h2 {
          position: relative;
          display: inline-block;
          margin-bottom: 20px;
          padding: 0 20px 10px 0;
          line-height: 40px;
        }
        .image {
          width: 100%;
          height: 100%;
          display: block;
        }
      }
      .col-half + .col-half {
        position: relative;
        padding: 0;
        height: 400px;
      }
    }

    .row-method:nth-child(odd) {
      .col-half {
        float: left;
        .text {
          left: 0;
        }
      }
      .col-half + .col-half {
        right: 0;
      }
    }
    .row-method:nth-child(even) {
      .col-half {
        float: right;
        .text {
          right: 0;
        }
      }
      .col-half + .col-half {
        left: 0;
      }
    }
  }
  #project-container {
    overflow-x:hidden;
  }
  .projects {
    max-width:100%;
    display:block;
    float:left;
    width:100%;
    padding:0;
    .project-item {
      padding:0;
      margin:0;
      .project-image {
        width:100%;
        .image {
          height:300px !important;
        }
      }
      .project-desc {
        width:100%;
        padding:30px;
        text-align:center;
        h2 {
          text-align:center;
          font-size:2.4em;
        }
        .icon-set {
          display:inline-block;
          float:none;
          li {
            display:inline-block;
            float:none;
          }
        }
      }
    }
  }
  .project-container {
    width:100%;
    padding:15px 0;
  }
  article.project {
    a {
      .title{
        .short {
          display:none;
        }
        h2 {
          padding:0 0 10px 0;
        }
      }
    }
  }

  .list-advantage {
    display:none;
  }
  .advantage {
    max-width:100%;
  }
  .list {
    li {
      padding:175px 25px 0 25px;
      border-top:0;
      margin:0 0 30px 0;
      text-align:center;
      img {
        top:0 !important;
        transform:translate(0, 0) !important;
        left:0 !important;
        right:0 !important;
        margin:0 auto;
        padding-bottom:15px;

      }
      &:nth-child(odd) {
        padding-left:25px;
      }
      &:nth-child(even) {
        padding-right:25px;
      }
    }
  }
  #references {
    .list-references {
      li {
        width:100%;
        height:220px;
        border-right:0;
        border-left:0;
      }
    }
  }
  #case-image {
    background:transparent;
    padding:0 25px;
    width:100%;
    height:auto;
    min-height:1px;
    ul {
      width:100%;
      height:auto;
      li {
        img {
          max-width:100%;
          width:100%;
          height:auto;
        }
      }
    }
  }
  #case {
    min-height:270px;
  }
  .short-image {
    margin:170px auto 0 auto;
    p {
      font-size:1.25em;
      line-height:2.1em;
      &.company {
        padding:0 15px;
      }
    }
  }
  #project-categories {
    padding:45px 0 45px 0;
  }
  #card-contact {
    left:0;
    right:0;
    margin:0 auto;
  }
  .contact-info {
    .container-fluid {
      h2 {
        text-align:center;
      }
    }
  }
  .contactform {
    display:block;
    text-align:center;
    .btn-primary {
      width:100%;
    }
  }
  .arrow-down {
    width:0;
    height:0;
    display:none;
  }
  .btn-back-icon {
    bottom:15px;
    left:15px;
  }
  footer {
    padding-top:15px;
    h4 {
      margin-top:45px;
    }
    .container-fluid {
      .column {
        padding:0;
        text-align:center;
         i {
           display:none;
         }
        ul {
          margin:0 !important;
        }
      }
      .col-md-4 {
        &:last-child {
          .column {
            padding-left:0px !important;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 479px) {
  footer {
    .container-fluid {
      .col-md-4 {
        &:last-child {
          .column {
            padding-left:0px !important;

            .certificate-logo {
              img {
                max-width:160px;
              }
              img + a {
                margin-left:0px;
                margin-top:25px;
                img {
                  max-width:220px;
                }
              }
            }
          }
        }
      }
    }
  }
}