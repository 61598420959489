#news-overview {
  padding:90px 45px 45px 45px;
}
.thumbnail {
  float:left;
  padding:0;
  margin:0 0 30px 0;
  width:100%;
  border:0;
  .album {
    float:left;
    width:100%;
    padding:0;
    display: block;
    position:relative;
    z-index:10;
    border:1px solid #eee;
    background:#fafafa;
    border-radius:0;
    transition:ease all 0.25s;
    .album-image {
      float:left;
      width:100%;
      position:relative;
      z-index:1;
      overflow:hidden;
      h4 {
        position:absolute;
        bottom:15px;
        left:25px;
        z-index:101;
        color:white;
        font-size:20px;
        font-weight:700;
        background:black;
        padding:8px 12px;
      }
      &:before {
        content:"\f061";
        font-family: FontAwesome;
        position:absolute;
        right:50px;
        bottom:20px;
        color:black;
        font-size:32px;
        transition:ease all 0.35s;
        z-index:100;
        opacity:0;
      }
      &:after {
        content:"";
        position:absolute;
        bottom:-100%;
        left:-100%;
        width:100%;
        height:100%;
        transition:ease all 0.2s;
        background: rgb(189,23,23); /* Old browsers */
        background: -moz-linear-gradient(left, rgba(189,23,23,1) 0%, rgba(231,24,102,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, rgba(189,23,23,1) 0%,rgba(231,24,102,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, rgba(189,23,23,1) 0%,rgba(231,24,102,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bd1717', endColorstr='#e71866',GradientType=1 ); /* IE6-9 */
        opacity:0;
      }
    }
    .album-desc {
      position:relative;
      z-index:10;
      padding:25px;
      float:left;
      width:100%;
      h2 {
        margin-top: 0;
        margin-bottom: 6px;
        font-size:2.5em;
        line-height:40px;
        padding:0;
        color:black;
        transition:ease all 0.3s;
      }
      p {
        margin:0;
        padding:0;
        color:#333;
        font-family: $font-source;
        font-weight:300;
      }
    }
    &:hover,
    &:focus {
      box-shadow:0 1px 3px rgba(0,0,0,0.075);
      .album-image {
        &:before {
          color:black;
          opacity:1;
          right:25px;
        }
        &:after {
          bottom:0;
          left:0;
          opacity:0.75;
        }
      }
    }
  }
}

#news-detail {
  i {
    margin-right:5px;
  }
}