.projects {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-overflow-scrolling: touch;
  padding:100px 150px;
  max-width:1600px;
  margin:0 auto;
}

.project-item {
  float:left;
  position:relative;
  width:100%;
  margin:0 0 90px 0;
  padding:30px;
  background:#fafafa;
  .project-image,
  .project-desc {
    width:50%;
    position:relative;
  }
  .project-image {
    .image {
      background-size:cover;
      width:100%;
      height:500px;
      position:relative;
    }
  }
  .project-desc {
    padding:30px 60px;
    h2 {
      font-size:3.25em;
      padding:0 0 20px 0;
    }
    .icon-set {
      list-style:none;
      margin:0 0 45px 0;
      padding:0;
      width:100%;
      display:block;
      float:left;
      li {
        float:left;
        margin:0 14px 0 0;
        a {
          display:inline-block;
          text-align:center;
          margin:0 auto;
          position:relative;
        }
      }
    }
    .btn-primary {
      &:hover,
      &:focus {
        &:before {
          background: rgb(189,23,23); /* Old browsers */
          background: -moz-linear-gradient(left, rgba(189,23,23,1) 0%, rgba(231,24,102,1) 100%); /* FF3.6-15 */
          background: -webkit-linear-gradient(left, rgba(189,23,23,1) 0%,rgba(231,24,102,1) 100%); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(to right, rgba(189,23,23,1) 0%,rgba(231,24,102,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bd1717', endColorstr='#e71866',GradientType=1 ); /* IE6-9 */
        }
        background: rgb(189,23,23); /* Old browsers */
        background: -moz-linear-gradient(left, rgba(189,23,23,1) 0%, rgba(231,24,102,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, rgba(189,23,23,1) 0%,rgba(231,24,102,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, rgba(189,23,23,1) 0%,rgba(231,24,102,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bd1717', endColorstr='#e71866',GradientType=1 ); /* IE6-9 */
        border-color:$red;
      }
    }
  }
  &:first-child,
  &:nth-child(3n+1) {
    .project-image,
    .project-desc {
      width:100%;
    }
    .project-image {
      .image {
        height:600px;
      }
    }
    .project-desc {
      text-align:center;
      .icon-set {
        display:inherit;
        text-align:center;
        float:none;
        li {
          float:none;
          display:inline;
        }
      }
    }
  }
  &:nth-child(odd) {
    .project-image {
      float:left;
    }
    .project-desc {
      float:right;
    }
  }
  &:nth-child(even) {
    .project-image {
      float:right;
    }
    .project-desc {
      float:left;
    }
  }
}


.project-container {
  float:left;
  position:relative;
  width:50%;
  padding:15px;
}
.project-container:nth-child(5n+1) {
  width:100%;
}
article.project:nth-child(5n+1) {
  width: 100%;
  position: relative;
}
article.project {
  width:50%;
  min-height:500px;
  position:relative;
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
  -ms-transform: translateY(0);
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-animation: none;
  -o-animation: none;
  animation: none;
  overflow:hidden;
  a {
    display: block;
    background-color: #111;
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    -webkit-transition: 0.2s ease;
    -o-transition: 0.2s ease;
    transition: 0.2s ease;
    -webkit-overflow-scrolling: touch;
    .title {
      position: absolute;
      left:50%;
      top:50%;
      transform: translate(-50%,-50%);
      color: white;
      z-index: 2;
      padding:0;
      width:80%;
      h2 {
        color:white;
        text-align:center;
        margin:0;
        padding:0 40px 10px 40px;
        position:relative;
        font-size:2.8em;
        &:after {
          content:"";
          width:75px;
          height:3px;
          background:white;
          position:absolute;
          left:0;
          right:0;
          bottom:0;
          margin:0 auto;
        }
      }
      .short {
        opacity: 0;
        overflow: hidden;
        max-height: 0;
        max-width:100%;
        min-width:100%;
        font-size: 1.5em;
        line-height:28px;
        padding:15px 0 0 0;
        color:white;
        font-weight:300;
        white-space: inherit;
        text-align:center;
        -webkit-transition: 0.4s ease;
        -o-transition: 0.4s ease;
        transition: 0.4s ease;
        -moz-transform: translateZ(0);
        -o-transform: translateZ(0);
        -ms-transform: translateZ(0);
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        p {
          margin:0;
          padding:0;
          line-height:32px;
        }
      }
    }
    .image {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-size: cover;
      background-position: center;
      -webkit-transition: 0.4s ease;
      -o-transition: 0.4s ease;
      transition: 0.4s ease;
      -moz-transform: translateZ(0);
      -o-transform: translateZ(0);
      -ms-transform: translateZ(0);
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0.75;
        background: -moz-linear-gradient(top, transparent 0%, #000 100%);
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, transparent), color-stop(100%, #000));
        background: -webkit-linear-gradient(top, transparent 0%, #000 100%);
        background: -o-linear-gradient(top, transparent 0%, #000 100%);
        background: -ms-linear-gradient(top, transparent 0%, #000 100%);
        background: linear-gradient(to bottom, transparent 0%, #000 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='rgba(black,0.1)', endColorstr='rgba(black,1)',GradientType=0 );
        -webkit-transition: 0.4s ease;
        -o-transition: 0.4s ease;
        transition: 0.4s ease;
      }
      /*
      &:after {
        content:"";
        background: rgb(189,23,23);
        background: -moz-linear-gradient(left, rgba(189,23,23,1) 0%, rgba(231,24,102,1) 100%);
        background: -webkit-linear-gradient(left, rgba(189,23,23,1) 0%,rgba(231,24,102,1) 100%);
        background: linear-gradient(to right, rgba(189,23,23,1) 0%,rgba(231,24,102,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bd1717', endColorstr='#e71866',GradientType=1 );
        position:absolute;
        width:100%;
        height:100%;
        opacity:0.5;
        top:0;
        left:0;
      }
      */
    }
    &:hover,
    &:focus {
      .title {
        .short  {
          opacity: 1;
          max-height: 200px;
        }
      }
    }
  }
  &:nth-child(5n+1)::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 50%;
  }
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 100%;
  }
}
.icons {
  position:absolute;
  left:15px;
  bottom:15px;
}
.icon-makelaar {
  width:30px;
  height:30px;
  display:block;
  background:url(/images/icons/projects/makelaar.png);
  background-size:30px 30px;
}

.start {
  text-align:center;
  max-width:800px;
  margin:0 auto 90px auto;
}
.introduction {
  background:$red;
  color:white;
}
.arrow-down {
  width:1px;
  height:60px;
  background:white;
  position:absolute;
  left:0;
  bottom:50px;
  right:0;
  margin:0 auto;
  z-index:100;
  &:before,
  &:after{
    content:"";
    position:absolute;
    bottom:0;
    left:-8px;
    right:0;
    margin:0 auto;
    width:1px;
    border-left:1px solid white;
    height:18px;
    background:white;
    transform:rotate(-20deg);
  }
  &:after {
    left:0;
    right:-8px;
    transform:rotate(20deg);
  }
}
#case {
  min-height:800px;
}
#case-image {
  background:url('/images/laptop2.png') no-repeat;
  background-size:1238px 754px;
  background-position:center center;
  width:100%;
  height:760px;
  min-height:760px;
  position:absolute;
  top:-150px;
  left:0;
  right:0;
  margin:0 auto;
  ul {
    margin:76px auto 0 auto;
    padding:0;
    list-style:none;
    width:825px;
    height:515px;
    overflow:hidden;
    li {
      img {
        text-align: center;
        margin: 0 auto;
        display: block;
        width:825px;
        height:515px;
      }
    }
  }
}
.short-image {
  margin:500px auto 0 auto;
  max-width:800px;
  color:white;
  font-size:16px;
  line-height:30px;
  text-align:center;
  h2 {
    color:white;
    font-size:40px;
  }
  p {
    color:white;
    font-weight:300;
    font-size:30px;
    margin:0;
    line-height:1.75em;
    font-style:italic;
    &.name {
      margin-top:40px;
      font-size:24px;
      font-weight:700;
      text-transform:uppercase;
      opacity:0.95;
      font-style:normal;
    }
    &.company {
      opacity:0.75;
      font-size:20px;
      font-style:normal;
    }
  }
}
.btn-back-icon {
  position:fixed;
  bottom:50px;
  left:50px;
  background:rgba(255,255,255,1);
  border-radius:50%;
  border:2px solid #eee;
  width:50px;
  height:50px;
  text-align:center;
  i {
    color:#ccc;
    font-size:20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-right:2px;
  }
  &:hover,
  &:focus {
    background:#ccc;
    color:white;
    i {
      color:white;
    }
  }
}

#project-categories {
  ul {
    margin: 0 auto;
    padding: 0;
    list-style: none;
    position: relative;
    text-align: center;
    li {
      width:150px;
      margin:0 auto;
      text-align:center;
      display: inline-block;
      img {
        display:block;
        text-align:center;
        margin:0 auto;
      }
      span {
        text-align:center;
        margin:10px auto;
        display:block;
        text-transform: lowercase;
      }
    }
  }
}