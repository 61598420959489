header {
  width:100%;
  height:calc(100vh - 120px);
  min-height:300px;
  position:relative;
  display:block;
  overflow:hidden;
  &.large {
    h1 {
      width:66.66667%;
      position:absolute;
      top:50%;
      left:50%;
      transform: translate(-50%,-50%);
      color:white;
      font-size:5em;
      text-align:center;
      font-weight:900;
      font-family: 'Source Sans Pro', sans-serif;
      z-index:301;
      a {
        color:white;
        display:block;
        font-size:25px;
        margin-top:25px;
        i {
          font-size:18px;
        }
      }
    }
  }
  .bg-image {
    width:114%;
    height:114%;
    min-height:300px;
    position:absolute;
    top:-7%;
    left:-7%;
    display:block;
    background:url('/images/header/bg2.jpg') no-repeat;
    background-size:cover;
    background-position: center center;
    z-index:0;
  }
  &.small {
    height:281px;
    background:url('/images/header/contact.png') repeat-x;
    background-position:0 0;
    width:100%;
    min-height:281px;
    h1 {
      margin:0;
      padding:0;
    }
  }
  &.heading {
    width:100%;
    min-height:600px;
    max-height:700px;
    .header-text {
      width:66.66667%;
      position:absolute;
      top:50%;
      left:50%;
      transform: translate(-50%,-50%);
      color:white;
      text-align:center;
      font-family: 'Source Sans Pro', sans-serif;
      z-index:300;
      h1 {
        font-weight:900;
        font-size:5em;
        margin:0 0 15px 0;
        padding:0;
        position:relative;
        z-index:300;
      }
      p {
        color:white;
        font-size:1.7em;
        padding:0 100px;
        margin:0;
      }
      .btn-primary {
        margin-top:30px;
      }
    }
    &:after {
      content:"";
      background: rgb(189,23,23); /* Old browsers */
      background: -moz-linear-gradient(left, rgba(189,23,23,1) 0%, rgba(231,24,102,1) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(left, rgba(189,23,23,1) 0%,rgba(231,24,102,1) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, rgba(189,23,23,1) 0%,rgba(231,24,102,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bd1717', endColorstr='#e71866',GradientType=1 ); /* IE6-9 */
      position:absolute;
      width:100%;
      height:100%;
      opacity:0.85;
    }
  }
  &.header-contact {
    background:url('/images/header/building.jpg') no-repeat;
    background-size:cover;
    background-position:center center;
    background-attachment:fixed;
  }
  &.header-referenties {
    background:url('/images/header/references.jpg') no-repeat;
    background-size:cover;
    background-position:center center;
    background-attachment:fixed;
  }
}
.bg-header {
  width:100%;
  padding-top:360px;
  background:url(/images/header/contact-bg.png) repeat-x;
  background-position:top left;
  float:left;
  position:relative;
}

@keyframes rotateIn {
  from {
    transform-origin: center;
    transform: rotate3d(0, 0, 1, -3deg);
    opacity: 1;
  }

  to {
    transform-origin: center;
    transform: none;
    opacity: 1;
  }
}

.rotateIn {
  animation-name: rotateIn;
}