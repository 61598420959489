@media only screen and (max-width : 1199px) {

  h2 {
    font-size:2.75em;
    line-height:48px;
  }

  header {
    &.large {
      max-height:650px;
      h1 {
        font-size:3.25em;
      }
    }
    &.heading {
      .header-text {
        width:75%;
        h1 {
          font-size:3.25em;
        }
        p {
          padding:0 40px;
          font-size:1.5em;
        }
      }
    }
  }

  #home-projects {
    .item {
      .title {
        h2 {
          font-size:2em;
          line-height:36px;
        }
        .short {
          font-size:1.25em;
        }
      }
    }
  }

  #methods {
    .row-method {
      .col-half {
        .text{
          padding:0 40px;
          h2 {
            font-size:2.5em;
            margin-bottom:0;
          }
          p {
            font-size:1.1em;
          }
        }
      }
    }
  }

  #references {
    .list-references {
      li {
        width:33.3333333%;
        height:350px;
      }
    }
  }

  article.project {
    min-height:350px;
    a {
      .title {
        h2 {
          font-size:2em;
          line-height:36px;
        }
        .short {
          font-size:1.25em;
        }
      }
    }
  }

  footer {
    .container-fluid {
      .col-md-4 {
        &:last-child {
          .column {
            padding-left:50px !important;

            .certificate-logo {
              img {
                max-width:160px;
              }
              img + a {
                margin-left:0px;
                margin-top:25px;
                display:block;
                img {
                  max-width:220px;
                }
              }
            }
          }
        }
      }
    }
  }

}