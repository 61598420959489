#home-support {
  position: relative;
  float: left;
  width:100%;
  .center {
    text-align:center;
    padding:0 0;
    max-width:900px;
    margin:0 auto;
  }
  .information {
    width: 50%;
    float: left;
    position: relative;
    padding: 90px 60px 0 0;
    p {
      max-width: 90%;
    }
  }
  .image {
    max-width: 50%;
    width: 50%;
    display: block;
    padding: 110px 30px 0 90px;
    float: right;
    img {
      max-width: 100%;
      max-height: 450px;
    }
  }
  .buttons {
    margin:60px 0 0 0;
    .icon-item {
      width:33.3333%;
      float:left;
      display:block;
      margin:0 auto;
      a {
        margin:0 auto;
        display:block;
        position: relative;
        border-radius:50%;
        width:250px;
        height:250px;
        background:#fafafa;
        border:1px solid #eee;
        transition:ease all 0.25s;
        overflow:hidden;
        .icon-content {
          position:absolute;
          left:50%;
          top:50%;
          transform:translate(-50%, -50%);
          h3 {
            margin:0 0 15px 0;
            padding:0;
            text-align:center;
            font-size:22px;
            transition:ease all 0.25s;
            color: $red;
            background: -webkit-linear-gradient(left, #bd1717, #e71866);
            background: -o-linear-gradient(right, #bd1717, #e71866);
            background: -moz-linear-gradient(right, #bd1717, #e71866);
            background: linear-gradient(to right, #bd1717, #e71866);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
        img {
          transition:ease all 0.25s;
          margin:0 auto;
          text-align:center;
        }
        &:before {
          content:"\f054";
          font-family: FontAwesome;
          width:60px;
          height:60px;
          background:#bd1717;
          padding:20px 23px 20px 0;
          color:white;
          position:absolute;
          right:-60px;
          opacity:0;
          top:50%;
          transform:translate(0,-50%);
          border-radius:50%;
          transition:ease all 0.25s;
        }
        &:hover {
          width:250px;
          height:250px;
          &:before {
            opacity:1;
            right:-30px;
          }
          h3 {
            font-size:26px;
          }
          img {
            width:100px !important;
            height:100px !important;
          }
        }
      }
    }
  }
}

#home-projects {
  width: 100%;
  padding: 0;
  max-width: 1400px;
  margin: 0 auto 90px auto;
  display: flex;
  .w-100,
  .w-50,
  .w-25 {
    float: left;
    overflow:hidden;
  }
  .w-100 {
    width: 100%;
    border-right: 0;
  }
  .w-50 {
    width: 50%;
  }
  .w-25 {
    width: 25%;
  }
  .h-50 {
    height: 300px;
  }
  .h-100 {
    height: 600px;
  }
  .item {
    background:white;
    padding: 10px;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    z-index: 0;
    position:relative;
    cursor:pointer;
    .title {
      position: absolute;
      left:50%;
      top:50%;
      transform: translate(-50%,-50%);
      color: white;
      z-index: 2;
      padding:0;
      width:80%;
      h2 {
        color:white;
        text-align:center;
        margin:0;
        padding:0 0px 10px 0px;
        position:relative;
        font-size:2.75em;
        line-height:48px;
        &:after {
          content:"";
          width:75px;
          height:3px;
          background:white;
          position:absolute;
          left:0;
          right:0;
          bottom:0;
          margin:0 auto;
        }
      }
      .short {
        opacity: 0;
        overflow: hidden;
        max-height: 0;
        max-width:100%;
        min-width:100%;
        font-size: 1.5em;
        line-height:28px;
        padding:15px 0 0 0;
        color:white;
        font-weight:300;
        white-space: inherit;
        text-align:center;
        -webkit-transition: 0.4s ease;
        -o-transition: 0.4s ease;
        transition: 0.4s ease;
        -moz-transform: translateZ(0);
        -o-transform: translateZ(0);
        -ms-transform: translateZ(0);
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        p {
          margin:0;
          padding:0;
          line-height:32px;
        }
      }
    }
    h3 {
      font-family: 'Source Sans Pro', sans-serif;
      font-weight:400;
      font-size:20px;
      bottom:15px;
      right:30px;
      position: absolute;
      width: 280px;
      display: block;
      margin:0;
      padding:0;
      opacity:1;
      text-align:right;
    }
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0.75;
      background: -moz-linear-gradient(top, transparent 0%, #000 100%);
      background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, transparent), color-stop(100%, #000));
      background: -webkit-linear-gradient(top, transparent 0%, #000 100%);
      background: -o-linear-gradient(top, transparent 0%, #000 100%);
      background: -ms-linear-gradient(top, transparent 0%, #000 100%);
      background: linear-gradient(to bottom, transparent 0%, #000 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='rgba(black,0.1)', endColorstr='rgba(black,1)',GradientType=0 );
      -webkit-transition: 0.4s ease;
      -o-transition: 0.4s ease;
      transition: 0.4s ease;
    }
    &:hover {
      .title {
        .short  {
          opacity: 1;
          max-height: 200px;
        }
      }
    }
  }
}
