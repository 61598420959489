#research_messages {
  h2 {
    color:#333;
  }
  .list-messages {
    margin:0;
    padding:0;
    list-style:none;
    li {
      float:left;
      width:100%;
      display:block;
      font-size:100%;
      a {
        display:block;
        width:100%;
        text-decoration:none;
        float:left;
        position:relative;
        padding:0 0 15px 0;
        margin:0 0 15px 0;
        border-bottom:1px solid #ccc;
        h2 {
          font-size:2em;
          margin:0;
          padding:0;
        }
        p {
          margin:0;
          padding:0;
          span {
            display:block;
            color:#333;
            font-weight:400;
          }
        }
        &:hover,
        &:focus {
          text-decoration:none;
          p {
            span {
              text-decoration:underline;
            }
          }
        }
      }
      &:last-child {
        a {
          border-bottom:0;
        }
      }
    }
  }
}

#research_credits {
  width:100%;
  position:relative;
  float:left;
  border-top:1px solid #eee;
  padding:60px 0;
  text-align:center;
  .logo {
    width:50%;
    float:left;
    display:block;
    position:relative;
    font-size:20px;
    text-align:center;
    span {
      display:block;
    }
    img {
      display:block;
      margin:0 auto;
      text-align:center;
      margin-top:15px;
    }
  }
}

.footer-content {
  display:block;
  width:100%;
  float:left;
  position:relative;
  border-top:1px solid #eee;
  padding:15px 0;
  a {
    font-size:1.25em;
    &:hover,
    &:focus {
      text-decoration:none;
      color:black;
    }
  }
}