
.contact-info {
  padding-top:30px;
  .container-fluid {
    max-width: 1400px;
    margin: 0 auto;
    h2 {
      font-size:2.5em;
    }
    .list-group-item {
      font-size:1.25em;
      font-weight:300;
      font-family: 'Source Sans Pro', sans-serif;
      border:0;
      line-height:1.8em;
      padding:3px 0 0 0;
      &:first-child {
        padding-bottom:18px;
      }
      &:hover,
      &:focus {
        background:transparent;
      }
      a {
        &:hover,
        &:focus {
          background:transparent;
          color:$red;
        }
      }

    }
    .col-sm-6 {
      padding-right:60px;
    }
    .col-sm-6 + .col-sm-6 {
      padding-right:0;
      padding-left:60px;
    }
  }
}
.socialmedia {
  margin:0;
  padding:0;
  list-style:none;
  li {
    float:left;
    margin-right:5px;
    a {
      display:block;
      position:relative;
      float:left;
      width:36px;
      height:36px;
      background: -webkit-linear-gradient(left, #bd1717, #e71866);
      background: -o-linear-gradient(right, #bd1717, #e71866);
      background: -moz-linear-gradient(right, #bd1717, #e71866);
      background: linear-gradient(to right, #bd1717, #e71866);
      border-bottom:3px solid #7f0b0b;
      border-radius:5px;
      color:white;
      text-align:center;
      padding:7px 0;
      -webkit-transition: background-color 2s ease-out;
      -moz-transition: background-color 2s ease-out;
      -o-transition: background-color 2s ease-out;
      transition: background-color 2s ease-out;
      z-index:100;
      i {
        font-size:1.5em;
        color:white;
      }
      &:before {
        border-radius: inherit;
        background:$red;
        background: -webkit-linear-gradient(left, #a91313, #bd1717);
        background: -o-linear-gradient(right, #a91313, #bd1717);
        background: -moz-linear-gradient(right, #a91313, #bd1717);
        background: linear-gradient(to right, #a91313, #bd1717);
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        top: 0; left: 0;
        opacity: 0;
        width: 100%;
        z-index: -100;
        transition: opacity 0.45s;
      }
      &:hover,
      &:focus {
        &:before {
          content:"";
          opacity:1;
        }
      }
    }
    &:first-child {
      font-size:1.25em;
      font-weight:300;
      padding-top:5px;
      padding-right:5px;
    }
  }
}
.maps {
  margin-top:60px;
  width:100%;
  height:650px;
  float:left;
  position:relative;
}

#card-contact {
  position:absolute;
  z-index:100;
  background:white;
  top:200px;
  left:100px;
  width:330px;
  height:280px;
  padding:45px;
  box-shadow:0 0 3px rgba(0,0,0,0.3);
  h2 {
    margin:0;
    padding:0;
    color:$red;
    font-size:24px;
    line-height:24px;
  }
  p {
    margin:0 0 15px 0;
  }
}
