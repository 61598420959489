#references {
  .list-references {
    margin:0;
    padding:0;
    list-style:none;
    li {
      float:left;
      width:25%;
      height:400px;
      display:table;
      vertical-align: middle;
      background:#fff;
      border:1px solid #bbb;
      border-right:0;
      border-bottom:0;
      .reference {
        display:table-cell;
        vertical-align: middle;
        text-align:center;
        -moz-box-shadow:    inset 0 0 50px rgba(0,0,0,0.1);
        -webkit-box-shadow: inset 0 0 50px rgba(0,0,0,0.1);
        box-shadow:         inset 0 0 50px rgba(0,0,0,0.1);
        img {
          max-width:50%;
          display:block;
          margin:0 auto;
        }
      }
    }
  }
}