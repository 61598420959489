@media only screen and (max-width : 1599px) {
  footer {
    .container-fluid {
      .col-md-4:last-child {
        .column {
          padding-left:20px !important;
        }
      }
    }
  }
}
@media only screen and (max-width : 1449px) {
  footer {
    .container-fluid {
      .col-md-4:last-child {
        .column {
          padding-left:10px !important;
          .certificate-logo {
            img {
              max-width:120px;
              float:left;
            }
            img + a {
              margin-left:15px;
              margin-top:5px;
              float:left;
              display:block;
              img {
                max-width:180px;
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width : 1399px) {
  h2 {
    line-height:48px;
  }
  header {
    &.large {
      h1 {
        font-size:3.75em;
      }
    }
  }
  #methods {
    .row-method {
      .col-half {
        h2 {
          margin-bottom:0;
        }
        .text{
          padding:0 60px;
        }
      }
    }
  }

}