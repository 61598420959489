.method-list {
  margin:0;
  padding:0;
  list-style:none;
  li {
    float:left;
    width:25%;
    position:relative;
    margin:0 0 60px 0;
    a {
      span.image {
        width:140px;
        height:140px;
        background: #fafafa;
        border: 1px solid #eee;
        overflow: hidden;
        border-radius:50%;
        display:block;
        position:relative;
        margin:0 auto;
        transition:ease all 0.25s;
        -moz-box-shadow:    inset 0 0 0 rgba(0,0,0,0.4);
        -webkit-box-shadow: inset 0 0 0 rgba(0,0,0,0.4);
        box-shadow:         inset 0 0 0 rgba(0,0,0,0.4);
        img {
          position:absolute;
          top:50%;
          left:50%;
          transform:translate(-50%,-50%);
        }
      }
      span.text {
        color:$red;
        text-align:center;
        text-transform: uppercase;
        font-weight:bold;
        font-size:18px;
        margin:10px 0 0 0;
        display:block;
        width:100%;
        text-decoration: none;
        transition:ease all 0.25s;
      }
      color:$red;
      max-width:100%;
      display:block;
      &:hover,
      &:focus {
        text-decoration: none;
        color:black;
        span.image {
          border: 1px solid #000;
          background: #222;
          -moz-box-shadow:    inset 0 0 2px rgba(0,0,0,0.1);
          -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.1);
          box-shadow:         inset 0 0 2px rgba(0,0,0,0.1);
        }
        span.text {
          color:black;
          text-decoration: none;
        }
      }
    }
  }
}

#methods {
  padding:0;
  .main {
    max-width:100%;
  }
  .row-method {
    float:left;
    width:100%;
    position:relative;
    .col-half {
      min-height:550px;
      .text {
        position:absolute;
        top:50%;
        transform: translate(0,-50%);
        width:50%;
        padding:0 90px;
        margin-top:15px;
      }
      h2 {
        position:relative;
        display:inline-block;
        margin-bottom:20px;
        padding:0 20px 10px 0;
        line-height:50px;
        /*&:after {
          content:".";
          position:absolute;
          right:0;
          top:0;
          color:$red;
        }*/
      }
      .image {
        width:100%;
        height:100%;
        display:block;
      }
      #orientatie-image {
        background:url(/images/methods/orientatie.jpg);
        background-size:cover;
        background-position:center center;
      }
      #wireframe-image {
        background:url(/images/methods/wireframe.jpg);
        background-size:cover;
        background-position:center center;
      }
    }
    .col-half + .col-half {
      position:absolute;
      padding:0;
      height:100%;
    }
  }

  .row-method:nth-child(odd) {
    .col-half {
      float:left;
      .text {
        left:0;
      }
    }
    .col-half + .col-half {
      right:0;
    }
  }
  .row-method:nth-child(even) {
    .col-half {
      float:right;
      .text {
        right:0;
      }
    }
    .col-half + .col-half {
      left:0;
    }
  }
}