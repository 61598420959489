.form-control {
  height:46px;
  border:1px solid #ccc;
  box-shadow:none;
  border-radius:0;
  font-size:1.25em;
  font-weight:300;
  font-family: 'Source Sans Pro', sans-serif;
  &:focus {
    border-color:$red;
    -webkit-box-shadow: inset 0 0px 0px rgba(0,0,0,0),0 0 0px rgba(102,175,233,0);
    box-shadow: inset 0 0px 0px rgba(0,0,0,0),0 0 0px rgba(102,175,233,0);
  }
}
textarea.form-control {
  height:150px;
  resize:none;
}

.alert {
  border-radius: 0;
  font-size: 1.25em;
  ul {
    margin: 0 0 0 19px;
    padding: 0;
    li {
      padding: 2px 0;
    }
  }
}

.has-error {
  position: relative;
  .form-control {
    border-color: $red;
  }
  &:after {
    content: "\f06a";
    font-family: FontAwesome;
    position: absolute;
    right: 15px;
    top: 8px;
    color: $red;
    font-size: 20px;
  }
}

.alert-danger {
  background: rgba(238, 115, 11, 0.1);
  color: $red;
  border-color: $red;
  border: 0;
}


.form-control::-webkit-input-placeholder {
  color: #ccc;
}
.form-control:focus::-webkit-input-placeholder {
  color: black;
}
.form-control:-moz-placeholder {
  color: #ccc;
}
.form-control:focus:-moz-placeholder {
  color: black;
}
.form-control::-moz-placeholder {
  color: #ccc;
}
.form-control:focus::-moz-placeholder {
  color: black;
}
.form-control:-ms-input-placeholder {
  color: #ccc;
}
.form-control:focus:-ms-input-placeholder {
  color: black;
}
