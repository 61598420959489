footer {
  width:100%;
  padding:60px 0;
  float:left;
  display:block;
  background:black;
  color:white;
  min-height:300px;
  .container-fluid {
    max-width:1600px;
    margin:0 auto;
    .col-md-4 {
      &:last-child {
        .column {
          padding-left:70px !important;
        }
      }
    }
    .column {
      padding:0 30px;
      p, a {
        color:white;
      }
      .btn-primary {
        font-size:20px;
      }
      h4 {
        font-size:2.5em;
      }
      li {
        padding:4px 0;
        font-size:1.25em;
        font-weight:300;
        .fa-li {
          top:0.46em;
        }
      }
      .certificate-logo {
        float:left;
        width:100%;
        padding:15px 0;
        margin-top:54px;
        img{
          max-width:150px;
        }
        img + a {
          margin-left:25px;
          img {
            max-width:220px;
          }
        }
      }
    }
  }
}